import { createAsyncThunk } from '@reduxjs/toolkit';
import instance from '../user/operations';


export const fetchAllDocuments = createAsyncThunk(
    'documents/fetchAll',
    async (page, thunkApi) => {
        try {
            const response = await instance.get(`/documents`);
            return response.data;
        } catch (e) {
            return thunkApi.rejectWithValue(e.message);
        }
    }
);
export const addDocument = createAsyncThunk(
    'documents/add',
    async (body, { rejectWithValue, getState }) => {
        try {
            const accessToken = localStorage.getItem('accessToken')
            if (!accessToken) {
                return rejectWithValue("Unable to fetch user");
            }
            const result = await instance.post(`/documents`, body, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });

            return result.data;
        } catch ({ response }) {
            const { status, data } = response;
            const error = {
                status,
                message: data.message,
            };
            return rejectWithValue(error);
        }
    }
);
export const updateDocument = createAsyncThunk(
    'documents/update',
    async (body, { rejectWithValue, getState }) => {
        try {
            const accessToken = localStorage.getItem('accessToken')
            if (!accessToken) {
                return rejectWithValue("Unable to fetch user");
            }
            const result = await instance.patch(`/documents`, body, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });

            return result.data.data;
        } catch ({ response }) {
            const { status, data } = response;
            const error = {
                status,
                message: data.message,
            };
            return rejectWithValue(error);
        }
    }
);
export const deleteDocument = createAsyncThunk(
    'documents/delete',
    async (id, { rejectWithValue, getState }) => {
        try {
            const accessToken = localStorage.getItem('accessToken')
            if (!accessToken) {
                return rejectWithValue("Unable to fetch user");
            }
            const result = await instance.delete(`/documents/${id}`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });

            return result.data;
        } catch ({ response }) {
            const { status, data } = response;
            const error = {
                status,
                message: data.message,
            };
            return rejectWithValue(error);
        }
    }
);