import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const BASE_URL = `${process.env.REACT_APP_BASE_URL}/api` || "https://api.zcml.com.ua/api";

export const instance = axios.create({
  baseURL: BASE_URL,
});

const setToken = (token) => {
  if (token) {
    return (instance.defaults.headers.common.authorization = `Bearer ${token}`);
  }
  instance.defaults.headers.common.authorization = "";
};

const clearToken = () => {
  instance.defaults.headers.common.authorization = null;
};

instance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const storedAccessToken = localStorage.getItem("accessToken");
    if (error.response.status === 401) {
      const storedRefreshToken = localStorage.getItem("refreshToken");
      try {
        const { data } = await instance.post("/users/refresh", {
          refreshToken: storedRefreshToken,
        });
        setToken(data.accessToken);

        localStorage.setItem("accessToken", data.accessToken);
        localStorage.setItem("refreshToken", data.refreshToken);

        const updatedConfig = { ...error.config };

        updatedConfig.headers.Authorization = `Bearer ${data.accessToken}`;

        if (error.config.url !== "/users/current") {
          return instance(error.config);
        }
        return instance(updatedConfig);
      } catch (error) {
        return Promise.reject(error);
      }
    }
    if (error.response.status === 403 && storedAccessToken) {
      localStorage.setItem("accessToken", "");
      localStorage.setItem("refreshToken", "");
      // window.location.replace(`${process.env.REACT_APP_BASEURL}/signin`);
    }
    return Promise.reject(error);
  }
);

export default instance;

export const fetchAllUsers = createAsyncThunk(
  "admin/allUsers",
  async (page, { rejectWithValue, getState }) => {
    try {
      const accessToken = localStorage.getItem("accessToken");
      if (!accessToken) {
        return rejectWithValue("Unable to fetch user");
      }
      const result = await instance.get(`/users`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      return result.data;
    } catch ({ response }) {
      const { status, data } = response;
      const error = {
        status,
        message: data.message,
      };
      return rejectWithValue(error);
    }
  }
);
export const createUser = createAsyncThunk(
  "admin/createUser",
  async (body, { rejectWithValue, getState }) => {
    try {
      const accessToken = localStorage.getItem("accessToken");
      if (!accessToken) {
        return rejectWithValue("Unable to fetch user");
      }
      const result = await instance.post(`/users`, body, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      return result.data;
    } catch ({ response }) {
      const { status, data } = response;
      const error = {
        status,
        message: data.message,
      };
      return rejectWithValue(error);
    }
  }
);
export const currentUser = createAsyncThunk(
  "admin/currentUser",
  async (_, { rejectWithValue, getState }) => {
    try {
      const accessToken = localStorage.getItem("accessToken");
      if (!accessToken) {
        return rejectWithValue("Unable to fetch user");
      }
      setToken(accessToken);

      const result = await instance.get(`/users/current`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      return result.data;
    } catch ({ response }) {
      const { status, data } = response;
      const error = {
        status,
        message: data.message,
      };
      return rejectWithValue(error);
    }
  }
);
export const loginUser = createAsyncThunk(
  "users/login",
  async (body, thunkApi) => {
    try {
      const result = await instance.post("/users/login", body);

      setToken(result.data.accessToken);

      localStorage.setItem("accessToken", result.data.accessToken);
      localStorage.setItem("refreshToken", result.data.refreshToken);

      return result.data;
    } catch (e) {
      return thunkApi.rejectWithValue(e.message);
    }
  }
);
export const logoutUser = createAsyncThunk(
  "users/logout",
  async (_id, thunkApi) => {
    try {
      const result = await instance.post(`/users/logout`, _id);
      clearToken();
      localStorage.clear();
      return result.data;
    } catch (e) {
      return thunkApi.rejectWithValue(e.message);
    }
  }
);

export const deleteUser = createAsyncThunk(
  "users/delete",
  async (id, { rejectWithValue, getState }) => {
    try {
      const accessToken = localStorage.getItem("accessToken");
      if (!accessToken) {
        return rejectWithValue("Unable to fetch user");
      }
      const result = await instance.delete(`/users/${id}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      return result.data;
    } catch ({ response }) {
      const { status, data } = response;
      const error = {
        status,
        message: data.message,
      };
      return rejectWithValue(error);
    }
  }
);
