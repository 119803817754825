import styled from "@emotion/styled";
import { colors } from "../../components/const";
export const HeaderStyled = styled.header`
  top: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${colors.mainWhite};
  font-size: 0.75rem /* 12px */;
  line-height: 1rem /* 16px */;
  padding: 1rem /* 16px */;
  max-width: 1980px;
  margin: 0 auto;
  z-index: 1;
  ul {
    & .active {
      padding-bottom: 12px;
      border-bottom: 2px solid #0fa7b0;
    }
  }
`;
