import styled from '@emotion/styled';

import { mediaSizes } from '../const/media';
import { colors } from '../const/colors';

export const FormFields = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 28px;

  @media screen and (min-width: ${mediaSizes.tablet}) {
    margin-bottom: 50px;
  }
`;

export const InputWraper = styled.div`
  width: 100%;
  position: relative;

  & svg {
    width: 18px;
    height: 18px;
    stroke: ${colors.lightBlue};
    opacity: 0.5;
    position: absolute;
    top: 13px;
    left: 14px;

    &.error {
      stroke: ${colors.error};
      opacity: 0.8;
    }

    &.success {
      stroke: ${colors.success};
      opacity: 0.8;
    }

    &.icon {
      fill: ${colors.lightBlue};

      &.error-icon {
        fill: ${colors.error};
        opacity: 1;
      }

      &.success-icon {
        fill: ${colors.success};
        opacity: 1;
      }
    }

    @media screen and (min-width: ${mediaSizes.tablet}) {
      width: 16px;
      height: 16px;
      top: 20px;
      left: 16px;
    }
  }

  & div {
    position: absolute;
    font-size: 12px;
    line-height: 21px;

    @media screen and (min-width: ${mediaSizes.tablet}) {
      font-size: 14px;
    }

    &.error {
      color: ${colors.error};
    }

    &.success-message {
      color: ${colors.success};
    }
  }

  & input {
    border: 1px solid ${colors.lightBlue};;
    border-radius: 6px;
    width: 100%;
    height: 100%;
    background-color: transparent;
    padding: 12px 40px;
    color: ${colors.lightBlue};
    opacity: 0.8;

    font-size: 16px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: -0.02em;
    transition: all 0.25s cubic-bezier(0.4, 0, 0.2, 1);

    &:focus {
      border: 1px solid ${colors.darkBlue};
    }

    &:hover {
      border: 1px solid ${colors.darkBlue};
    }

    &:active {
      border: 1px solid ${colors.darkBlue};
    }

    &.error {
      border: 1px solid ${colors.error};
      opacity: 1;
    }

    &.success {
      border: 1px solid ${colors.success};
      opacity: 1;
    }
    @media screen and (min-width: ${mediaSizes.tablet}) {
      font-size: 18px;
      line-height: 27px;
      padding: 16px 50px;
    }

    &::placeholder {
      font-weight: 400;
      color: ${colors.lightBlue};
      opacity: 0.8;
    }

    &:focus::-webkit-input-placeholder {
      opacity: 0;
    }

    &:hover,
    placeholder {
      stroke: ${colors.mainWhite};
      fill: ${colors.mainWhite};
      color: ${colors.darkBlue};
      border-color: ${colors.darkBlue};
      opacity: 1;
    }
  }

  & .valid {
    width: 16px;
    height: 16px;
    fill: ${colors.error};
    opacity: 1;
    position: absolute;
    top: 13px;
    left: 224px;

    @media screen and (min-width: ${mediaSizes.tablet}) {
      width: 16px;
      height: 16px;
      top: 20px;
      left: 224;
    }
  }

  & .valid {
    fill: ${colors.success};
  }
`;
