import { createAsyncThunk } from "@reduxjs/toolkit";
import instance from "../user/operations";

export const fetchAllDoctors = createAsyncThunk(
  "doctors/fetchAll",
  async (page, thunkApi) => {
    try {
      const response = await instance.get(`/doctors`);
      return response.data;
    } catch (e) {
      return thunkApi.rejectWithValue(e.message);
    }
  }
);
export const addDoctor = createAsyncThunk(
  "doctors/add",
  async (body, { rejectWithValue, getState }) => {
    try {
      const accessToken = localStorage.getItem("accessToken");
      if (!accessToken) {
        return rejectWithValue("Unable to fetch user");
      }
      const result = await instance.post(`/doctors`, body, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      return result.data;
    } catch ({ response }) {
      const { status, data } = response;
      const error = {
        status,
        message: data.message,
      };
      return rejectWithValue(error);
    }
  }
);
export const updateDoctor = createAsyncThunk(
  "doctors/update",
  async (body, { rejectWithValue, getState }) => {
    try {
      const accessToken = localStorage.getItem("accessToken");
      if (!accessToken) {
        return rejectWithValue("Unable to fetch user");
      }
      const result = await instance.patch(`/doctors`, body, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      return result.data;
    } catch ({ response }) {
      const { status, data } = response;
      const error = {
        status,
        message: data.message,
      };
      return rejectWithValue(error);
    }
  }
);
export const deleteDoctor = createAsyncThunk(
  "doctors/delete",
  async (id, { rejectWithValue, getState }) => {
    try {
      const accessToken = localStorage.getItem("accessToken");
      if (!accessToken) {
        return rejectWithValue("Unable to fetch user");
      }
      const result = await instance.delete(`/doctors/${id}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      return result.data;
    } catch ({ response }) {
      const { status, data } = response;
      const error = {
        status,
        message: data.message,
      };
      return rejectWithValue(error);
    }
  }
);
