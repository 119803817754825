
import { pushRotate as Menu } from "react-burger-menu";
import "./BurgerMenu.css";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import { useState } from "react";
import {
    selectCurrentUserRole,
} from "../../store/user/selectors.js";
import Button from "@mui/material/Button";
import {
    AboutDropDown,
    DepartmentsDropDown,
    PatientsDropDown,
} from "../../components/dropDown";

const BurgerMenu = () => {
    const role = useSelector(selectCurrentUserRole);
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const menuOpen = () => {
        setIsMenuOpen(true);
    };
    const menuClose = () => {
        setIsMenuOpen(false);
    };

    const uppPageHandler = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };
    return (
        <Menu
            pageWrapId={"page-wrap"}
            outerContainerId={"outer-container"}
            right
            isOpen={isMenuOpen}
            onOpen={menuOpen}
            onClose={menuClose}
        >
            <DepartmentsDropDown onClick={menuClose} />
            <AboutDropDown onClick={menuClose} />
            {role === "ADMIN" ? (
                <ul className="flex gap-8 pl-8 items-center" onClick={menuClose}>
                    <li>
                        <NavLink to="/admin" className={"menu-item"} onClick={uppPageHandler}>
                            <Button
                                sx={{
                                    textTransform: "none",
                                    fontFamily: "inherit",
                                    color: "#1E6D9A",
                                    fontSize: "18px",
                                }}

                            >
                                Адмінка
                            </Button>
                        </NavLink>
                    </li>
                </ul>
            ) : (
                <PatientsDropDown onClick={menuClose} />
            )}
            <ul className="flex gap-8 pr-8 items-center border-r-2 border-grey" onClick={menuClose}>
                <li>
                    <NavLink to="/news" className={"menu-item"} onClick={uppPageHandler}>
                        <Button
                            sx={{
                                textTransform: "none",
                                fontFamily: "inherit",
                                fontWeigth: "600",
                                color: "#1E6D9A",
                                fontSize: "18px",
                            }}
                        >
                            Новини
                        </Button>
                    </NavLink>
                </li>
                <li>
                    <NavLink to="/contacts" className={"menu-item"} onClick={uppPageHandler}>
                        <Button
                            sx={{
                                textTransform: "none",
                                fontFamily: "inherit",
                                color: "#1E6D9A",
                                fontSize: "18px",
                            }}
                        >
                            Контакти
                        </Button>
                    </NavLink>
                </li>
            </ul>
        </Menu>
    );
};

export default BurgerMenu;
