import { createSlice } from '@reduxjs/toolkit'
import { fetchAllArticles, addArticle, deleteArticle } from './operations';

const initialState = {
    articles: [],
    loading: false,
    error: null,
}

export const articlesSlice = createSlice({
    name: 'articles',
    initialState,
    extraReducers: builder => {
        builder
            .addCase(fetchAllArticles.fulfilled, (state, { payload }) => {
                state.articles = payload;
                state.loading = false;

            })
            .addCase(fetchAllArticles.pending, (state) => {
                state.loading = true;

            })
            .addCase(fetchAllArticles.rejected, (state, { payload }) => {
                state.loading = false;
                state.error = payload;
            })
            .addCase(addArticle.fulfilled, (state, { payload }) => {
                state.articles.push(payload);
                state.loading = false;
            })
            .addCase(addArticle.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(addArticle.rejected, (state, { payload }) => {
                state.loading = false;
                state.error = payload;
            })
            .addCase(deleteArticle.fulfilled, (state, { payload }) => {
                state.articles = state.articles.filter((article) => article._id !== payload._id);
                state.loading = false;
            })
            .addCase(deleteArticle.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(deleteArticle.rejected, (state, { payload }) => {
                state.loading = false;
                state.error = payload;
            })
    },
})

export default articlesSlice.reducer