import { Dropdown } from "@mui/base/Dropdown";
import { Menu } from "@mui/base/Menu";
import { MenuButton as BaseMenuButton } from "@mui/base/MenuButton";
import { MenuItem as BaseMenuItem, menuItemClasses } from "@mui/base/MenuItem";
import { styled } from "@mui/system";
import { NavLink } from "react-router-dom";

export const ServicesDropDown = () => {
  return (
    <Dropdown>
      <MenuButton>Послуги</MenuButton>
      <Menu slots={{ listbox: Listbox }}>
        <MenuItem>
          <NavLink to="/">УЗД</NavLink>
        </MenuItem>
        <MenuItem>
          <NavLink to="/">Ендоскопія</NavLink>
        </MenuItem>
        <MenuItem>
          <NavLink to="/">Рентген</NavLink>
        </MenuItem>
        <MenuItem>
          <NavLink to="/">Лабораторія</NavLink>
        </MenuItem>
        <MenuItem>
          <NavLink to="/">Щеплення</NavLink>
        </MenuItem>
        <MenuItem>
          <NavLink to="/">Операції</NavLink>
        </MenuItem>
        <MenuItem>
          <NavLink to="/">Медчні огляди</NavLink>
        </MenuItem>
        <MenuItem>
          <NavLink to="/">Функціональна діагностика</NavLink>
        </MenuItem>
      </Menu>
    </Dropdown>
  );
};
export const DepartmentsDropDown = () => {
  return (
    <Dropdown>
      <MenuButton>Відділення</MenuButton>
      <Menu slots={{ listbox: Listbox }}>
        <NavLink to="/administration">
          <MenuItem>Адміністрація</MenuItem>
        </NavLink>
        <NavLink to="/polyclinic">
          <MenuItem>Поліклініка</MenuItem>
        </NavLink>

        <NavLink to="/reception">
          <MenuItem>Приймальне</MenuItem>
        </NavLink>

        <NavLink to="/reanimation">
          <MenuItem>Реанімація (АІТ)</MenuItem>
        </NavLink>

        <NavLink to="/infectious">
          <MenuItem>Інфекційне</MenuItem>
        </NavLink>

        <NavLink to="/surgery">
          <MenuItem>Хірургічне</MenuItem>
        </NavLink>

        <NavLink to="/therapy">
          <MenuItem>Терапевтичне</MenuItem>
        </NavLink>

        <NavLink to="/neurology">
          <MenuItem>Неврологічне</MenuItem>
        </NavLink>

        <NavLink to="/laboratory">
          <MenuItem>Лабораторія</MenuItem>
        </NavLink>

        <NavLink to="/traumatology">
          <MenuItem>Травматологія (ОЦБТ)</MenuItem>
        </NavLink>

        <NavLink to="/gynecology">
          <MenuItem>Акушерсько-гінекологічне</MenuItem>
        </NavLink>
      </Menu>
    </Dropdown>
  );
};
export const AboutDropDown = () => {
  return (
    <Dropdown>
      <MenuButton>Про нас</MenuButton>
      <Menu slots={{ listbox: Listbox }}>
        <NavLink to="/history">
          <MenuItem>Історія лікарні</MenuItem>
        </NavLink>
        <NavLink to="/sertificates">
          <MenuItem>Нагороди та сертифікати</MenuItem>
        </NavLink>
        <NavLink to="/publicinfo">
          <MenuItem>Публічна звітність</MenuItem>
        </NavLink>
      </Menu>
    </Dropdown>
  );
};
export const PatientsDropDown = () => {
  return (
    <Dropdown>
      <MenuButton>Пацієнтам</MenuButton>
      <Menu slots={{ listbox: Listbox }}>
        <a
          href="https://h24.ua/organizacia/8176-knp-zdolbunivska-cml/doctors"
          target="_blank"
          rel="noopener noreferrer"
          title="Медичний онлайн сервіс для пацієнтів і лікарів"
        >
          <MenuItem>Запис на прийом</MenuItem>
        </a>
        <a
          rel="noopener noreferrer"
          target="_blank"
          href="https://moz.gov.ua/prava-pacienta"
        >
          <MenuItem>Права пацієнта</MenuItem>
        </a>
        <a
          rel="noopener noreferrer"
          target="_blank"
          href="https://moz.gov.ua/obovjazki-pacientiv"
        >
          <MenuItem>Обовязки пацієтна</MenuItem>
        </a>

        <a
          rel="noopener noreferrer"
          target="_blank"
          href="https://www.msp.gov.ua/content/pilgi.html"
        >
          <MenuItem>Система пільг</MenuItem>
        </a>
        <a
          rel="noopener noreferrer"
          target="_blank"
          href="https://eliky.in.ua/hospital/167"
        >
          <MenuItem>Є ліки</MenuItem>
        </a>
        <NavLink to="/usefulInfo">
          <MenuItem>Корисна інформація</MenuItem>
        </NavLink>
      </Menu>
    </Dropdown>
  );
};

const blue = {
  50: "#F0F7FF",
  100: "#C2E0FF",
  200: "#99CCF3",
  300: "#66B2FF",
  400: "#3399FF",
  500: "#007FFF",
  600: "#0072E6",
  700: "#0059B3",
  800: "#004C99",
  900: "#003A75",
};

const grey = {
  50: "#F3F6F9",
  100: "#E5EAF2",
  200: "#DAE2ED",
  300: "#C7D0DD",
  400: "#B0B8C4",
  500: "#9DA8B7",
  600: "#6B7A90",
  700: "#434D5B",
  800: "#303740",
  900: "#1C2025",
};

const Listbox = styled("ul")(
  ({ theme }) => `
  font-family: inherit;
  font-size: 0.75rem;
  box-sizing: border-box;
  padding: 6px;
  margin: 12px 0;
  border-radius: 8px;
  overflow: auto;
  outline: 0px;
  background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
  border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]};
  color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  box-shadow: 0px 4px 10px ${
    theme.palette.mode === "dark" ? grey[900] : grey[200]
  };
  z-index: 3;
  `
);

const MenuItem = styled(BaseMenuItem)(
  ({ theme }) => `
  font-size: 18px;
  list-style: none;
  padding: 8px;
  border-radius: 8px;
  cursor: default;
  user-select: none;
  z-index: 4;
  &:last-of-type {
    border-bottom: none;
  }

  &.${menuItemClasses.focusVisible} {
    outline: 3px solid ${theme.palette.mode === "dark" ? blue[600] : blue[200]};
    background-color: ${theme.palette.mode === "dark" ? grey[800] : grey[100]};
    color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  }

  &.${menuItemClasses.disabled} {
    color: ${theme.palette.mode === "dark" ? grey[700] : grey[400]};
  }

  &:hover:not(.${menuItemClasses.disabled}) {
    background-color: ${theme.palette.mode === "dark" ? blue[900] : blue[50]};
    color: ${theme.palette.mode === "dark" ? blue[100] : blue[900]};
  }
  `
);

const MenuButton = styled(BaseMenuButton)(
  ({ theme }) => `
  font-family: inherit;
  font-size: 20px;
  font-weight: 400;
  line-height: 1.5;
  padding: 6px;
  border-radius: 8px;
  transition: all 150ms ease;
  cursor: pointer;
  overflow: hidden;
  background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
  color: ${theme.palette.mode === "dark" ? grey[200] : "#1E6D9A"};


  &:hover {
    background: ${theme.palette.mode === "dark" ? grey[800] : grey[50]};
    border-color: ${theme.palette.mode === "dark" ? grey[600] : grey[300]};
  }

  &:active {
    background: ${theme.palette.mode === "dark" ? grey[700] : grey[100]};
  }

  &:focus-visible {
    box-shadow: 0 0 0 4px ${
      theme.palette.mode === "dark" ? blue[300] : blue[200]
    };
    outline: none;
  }
  `
);
