import { createAsyncThunk } from '@reduxjs/toolkit';
import instance from '../user/operations';

export const fetchAllArticles = createAsyncThunk(
    'articles/fetchAll',
    async (_, thunkApi) => {
        try {
            const response = await instance.get(`/articles`);
            return response.data;
        } catch (e) {
            return thunkApi.rejectWithValue(e.message);
        }
    }
);
export const addArticle = createAsyncThunk(
    'articles/add',
    async (body, { rejectWithValue, getState }) => {
        try {
            const accessToken = localStorage.getItem('accessToken')
            if (!accessToken) {
                return rejectWithValue("Unable to fetch user");
            }
            const result = await instance.post(`/articles`, body, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });

            return result.data;
        } catch ({ response }) {
            const { status, data } = response;
            const error = {
                status,
                message: data.message,
            };
            return rejectWithValue(error);
        }
    }
);
export const updateArticle = createAsyncThunk(
    'articles/update',
    async (body, { rejectWithValue, getState }) => {
        try {
            const accessToken = localStorage.getItem('accessToken')
            if (!accessToken) {
                return rejectWithValue("Unable to fetch user");
            }
            const result = await instance.patch(`/articles`, body, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });

            return result.data;
        } catch ({ response }) {
            const { status, data } = response;
            const error = {
                status,
                message: data.message,
            };
            return rejectWithValue(error);
        }
    }
);
export const deleteArticle = createAsyncThunk(
    'articles/delete',
    async (id, { rejectWithValue, getState }) => {
        try {
            const accessToken = localStorage.getItem('accessToken')
            if (!accessToken) {
                return rejectWithValue("Unable to fetch user");
            }
            const result = await instance.delete(`/articles/${id}`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });

            return result.data;
        } catch ({ response }) {
            const { status, data } = response;
            const error = {
                status,
                message: data.message,
            };
            return rejectWithValue(error);
        }
    }
);