import styled from "@emotion/styled";
import { mediaSizes } from "../../components/const";
export const FooterStyled = styled.footer`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 20px;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  /* @media screen and (min-width: ${mediaSizes.laptop}) {
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: flex;
  } */
`;
