import { HeaderStyled } from "./Header.styled";
import logo from "../../assets/images/ZCML_logo.png";
import {
  AboutDropDown,
  DepartmentsDropDown,
  PatientsDropDown,
} from "../../components/dropDown";
import { NavLink } from "react-router-dom";
import Button from "@mui/material/Button";
import Clock from "react-live-clock";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import BurgerMenu from "../../components/burgerMenu";

const Header = () => {
  const isMobile = useMediaQuery({ maxWidth: 1139 });
  const { role } = useSelector((state) => state.auth.user);
  return (
    <HeaderStyled>
      <div className="flex w-fit justify-start">
        <div className="flex">
          <NavLink to="/" className="flex gap-6 items-center">
            <img src={logo} alt="logo" width={"80px"} />
            {!isMobile && (
              <div className="flex flex-col items-center">
                <span className="text-5xl text-darkBlue font-bold">
                  Здолбунівська
                </span>
                <span className="text-2xl text-lightBlue font-bold">
                  центральна міська лікарня
                </span>
              </div>
            )}
          </NavLink>
        </div>
      </div>
      <div className="flex">
        <Clock
          className="text-5xl lg:text-2xl xl:text-5xl text-darkBlue font-black"
          format="HH:mm"
          interval={1000}
          ticking={true}
        />
      </div>
      {!isMobile ? (
        <div className="flex flex-row">
          <ul className="flex gap-8 pr-8 items-center border-r-2 border-grey">
            <li>
              <DepartmentsDropDown />
            </li>
            <li>
              <AboutDropDown />
            </li>
            <li>
              <NavLink to="/news">
                <Button
                  sx={{
                    textTransform: "none",
                    fontFamily: "inherit",
                    fontWeigth: "600",
                    color: "#1E6D9A",
                    fontSize: "18px",
                  }}
                >
                  Новини
                </Button>
              </NavLink>
            </li>
            <li>
              <NavLink to="/contacts">
                <Button
                  sx={{
                    textTransform: "none",
                    fontFamily: "inherit",
                    color: "#1E6D9A",
                    fontSize: "18px",
                  }}
                >
                  Контакти
                </Button>
              </NavLink>
            </li>
          </ul>
          <ul className="flex gap-8 pl-8 items-center">
            {role === "ADMIN" ? (
              <li>
                <NavLink to="/admin">
                  <Button
                    sx={{
                      textTransform: "none",
                      fontFamily: "inherit",
                      color: "#1E6D9A",
                      fontSize: "18px",
                    }}
                  >
                    Адмінка
                  </Button>
                </NavLink>
              </li>
            ) : (
              <li>
                <PatientsDropDown />
              </li>
            )}
          </ul>
        </div>
      ) : (
        <div className="relative">
          <BurgerMenu />
        </div>
      )}
    </HeaderStyled>
  );
};
export default Header;
