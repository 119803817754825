import { createSlice } from "@reduxjs/toolkit";
import { createUser, currentUser, deleteUser, fetchAllUsers, loginUser, logoutUser } from "./operations";

const initialState = {
  user: {
    _id: "",
    email: "",
    name: "",
    accessToken: "",
    refreshToken: "",
    role: "",
  },
  users: [],
  isLoggedIn: false,
  loading: false,
  authError: null,
  error: null,
  count: 0,
  totalPages: 0,
  currentPage: 1,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setAuthError: (state, action) => {
      state.authError = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllUsers.fulfilled, (state, { payload }) => {
        state.users = payload;
        state.loading = false;
      })
      .addCase(fetchAllUsers.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAllUsers.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      .addCase(loginUser.fulfilled, (state, { payload }) => {
        state.user = payload;
        state.isLoggedIn = true;
        state.loading = false;
        state.authError = null;
        state.error = null;
      })
      .addCase(loginUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(loginUser.rejected, (state, { payload }) => {
        state.isLoggedIn = false;
        state.loading = false;
        state.error = payload;
      })
      .addCase(currentUser.fulfilled, (state, { payload }) => {
        state.isLoggedIn = true;
        state.user = payload;
        state.loading = false;
        state.authError = null;
        state.error = null;
      })
      .addCase(currentUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(currentUser.rejected, (state, { payload }) => {
        state.isLoggedIn = false;
        state.loading = false;
        state.error = payload;
      })
      .addCase(logoutUser.fulfilled, (state, { payload }) => {
        state.isLoggedIn = false;
        state.user = {
          _id: "",
          email: "",
          name: "",
          accessToken: "",
          refreshToken: "",
          role: "",
        };
        state.loading = false;
        state.authError = null;
        state.error = null;
      })
      .addCase(logoutUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(logoutUser.rejected, (state, { payload }) => {
        state.isLoggedIn = false;
        state.loading = false;
        state.user = {
          _id: "",
          email: "",
          name: "",
          accessToken: "",
          refreshToken: "",
          role: "",
        };
        state.error = payload;
      })
      .addCase(createUser.fulfilled, (state, { payload }) => {
        state.users.push(payload);
        state.loading = false;
      })
      .addCase(createUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createUser.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      .addCase(deleteUser.fulfilled, (state, { payload }) => {
        state.users = state.users.filter((user) => user._id !== payload._id);
        state.loading = false;
      })
      .addCase(deleteUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteUser.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      });
  },
});
export const { setAuthError } = userSlice.actions;
export default userSlice.reducer;
