import { createSlice } from '@reduxjs/toolkit'
import { fetchAllDocuments, addDocument, deleteDocument } from './operations';

const initialState = {
    documents: [],
    loading: false,
    error: null,
    count: 0,
    totalPages: 0,
    currentPage: 1,
}

export const documentsSlice = createSlice({
    name: 'documents',
    initialState,
    extraReducers: builder => {
        builder
            .addCase(fetchAllDocuments.fulfilled, (state, { payload }) => {
                state.documents = payload;
                state.loading = false;
                state.totalPages = Math.ceil(payload.count / 10);
                state.count = payload.count
            })
            .addCase(fetchAllDocuments.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchAllDocuments.rejected, (state, { payload }) => {
                state.loading = false;
                state.error = payload;
            })
            .addCase(addDocument.fulfilled, (state, { payload }) => {
                state.documents.push(payload);
                state.loading = false;
            })
            .addCase(addDocument.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(addDocument.rejected, (state, { payload }) => {
                state.loading = false;
                state.error = payload;
            })
            .addCase(deleteDocument.fulfilled, (state, { payload }) => {
                state.documents = state.documents.filter((document) => document._id !== payload._id);
                state.loading = false;
            })
            .addCase(deleteDocument.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(deleteDocument.rejected, (state, { payload }) => {
                state.loading = false;
                state.error = payload;
            })
    },
})

export default documentsSlice.reducer