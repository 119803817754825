import { createSlice } from '@reduxjs/toolkit'
import { addDoctor, deleteDoctor, fetchAllDoctors } from './operations';

const initialState = {
    doctors: [],
    loading: false,
    error: null,
    count: 0,
    totalPages: 0,
    currentPage: 1,
}

export const doctorsSlice = createSlice({
    name: 'doctors',
    initialState,
    extraReducers: builder => {
        builder
            .addCase(fetchAllDoctors.fulfilled, (state, { payload }) => {
                state.doctors = payload;
                state.loading = false;
                state.totalPages = Math.ceil(payload.count / 10);
                state.count = payload.count
            })
            .addCase(fetchAllDoctors.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchAllDoctors.rejected, (state, { payload }) => {
                state.loading = false;
                state.error = payload;
            })
            .addCase(addDoctor.fulfilled, (state, { payload }) => {
                state.doctors.push(payload);
                state.loading = false;
            })
            .addCase(addDoctor.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(addDoctor.rejected, (state, { payload }) => {
                state.loading = false;
                state.error = payload;
            })
            .addCase(deleteDoctor.fulfilled, (state, { payload }) => {
                state.doctors = state.doctors.filter((doctor) => doctor._id !== payload._id);
                state.loading = false;
            })
            .addCase(deleteDoctor.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(deleteDoctor.rejected, (state, { payload }) => {
                state.loading = false;
                state.error = payload;
            })
    },
})

export default doctorsSlice.reducer